import shortid from "shortid";

// Types
export enum FORM_ACTION {
    EDIT = "edit",
    CLONE = "clone",
    CREATE = "create"
}

// ZxFormsHelper
const actions = {
    isCreate: (action: FORM_ACTION) => action === FORM_ACTION.CREATE,
    isEdit: (action: FORM_ACTION) => action === FORM_ACTION.EDIT,
    isClone: (action: FORM_ACTION) => action === FORM_ACTION.CLONE
};
const generateId = componentType => `${componentType}_${shortid.generate()}`;
export const ZxFormsHelper = {
    actions,
    generateId
};
