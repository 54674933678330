<div class="form-group">
    <zx-forms-field-label [translateKeyLabel]="translateKey.label" [tooltipsConfigs]="tooltipsConfigs" [inputId]="inputId"></zx-forms-field-label>
    <input
        [id]="inputId"
        type="text"
        class="form-control"
        [ngClass]="{ 'is-invalid': isInvalid, disabled: isDisabled }"
        placeholder="{{ (translateKey.placeholder | translate) || (translateKey.label | translate) }}"
        [(ngModel)]="value"
        (ngModelChange)="notifyControlOnChange(value)"
        (blur)="notifyControlOnTouch($event)"
        [disabled]="isDisabled"
        [autocomplete]="autoComplete"
    />
    <small *ngIf="translateKey.bottomLabel">
        {{ translateKey.bottomLabel | translate }}
    </small>
    <zx-forms-field-errors *ngIf="isInvalid" [errors]="ngControl.control.errors"></zx-forms-field-errors>
</div>
